import nookies from "nookies";

export interface CookieType {
  getCookie: (key: string) => any;
  setCookie: (key: string, value: any, options?: object) => void;
  deleteCookie: (key: string, options?: object) => void;
}

export default function cookies(context?: any): CookieType {
  const defaultOptions = { path: "/", maxAge: 7 * 24 * 60 * 60 };
  const getCookie = (key: string) => {
    return nookies.get(context)[key];
  };

  const setCookie = (key: string, value: any, options = {}) => {
    options = { ...defaultOptions, ...options };
    nookies.set(context, key, value, options);
  };

  const deleteCookie = (key: string, options = {}) => {
    options = { ...defaultOptions, maxAge: 0, ...options };
    nookies.destroy(context, key, options);
  };

  return {
    getCookie,
    setCookie,
    deleteCookie,
  };
}
