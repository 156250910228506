import { logout } from "./loginClient";

export interface ApiResult<TData = any> {
  data?: TData;
  error?: string | string[];
  pagination?: null | unknown;
  success?: boolean;
}

export async function fetcher<TResult = ApiResult>(
  url: string,
  method: "GET" | "POST" | "DELETE" | "PUT",
  body: string | null,
  headers: HeadersInit = {},
): Promise<TResult> {
  const options = {
    body,
    method,
    headers: new Headers({ "Content-Type": "application/json", ...headers }),
  } as RequestInit;

  try {
    console.debug("before fetch", `URL: ${url}`, "options:", options);
    const res = await fetch(url, options);

    if (process.env.NODE_ENV !== "test") {
      const clonedResponse = res.clone();
      const responseContent = await clonedResponse.text();
      console.debug(
        "after fetch",
        `Status: ${res.status}`,
        `URL: ${url}`,
        "Options:",
        options,
        `Content: ${responseContent}`,
      );
    }

    if (res.status === 401 && !window.location.pathname.includes("signup")) {
      logout(true);
      return { success: false } as TResult;
    }

    if (!res.ok) return { ...(await res.json()), success: false };

    const json: TResult = { ...(await res.json()), success: true };

    return json;
  } catch (err) {
    console.error("after fetch", url, options, err);

    return {
      success: false,
      error: err,
    } as TResult;
  }
}
