import { ConnectedPlaidInstitution as BackendConnectedPlaidInstitution } from "src/employee/onboarding/types/backend";
import {
  ConnectedPlaidAccount,
  ConnectedPlaidInstitution,
} from "src/employee/onboarding/types/form-flow";
import ConnectedPlaidAccountSerializer from "./connectedPlaidAccountSerializer";

export interface ConnectedPlaidInstitutionSerializerResult {
  // serialize unnecessary since data is read-only
  deserialize: (
    responseObject: BackendConnectedPlaidInstitution,
  ) => ConnectedPlaidInstitution;
}

const ConnectedPlaidInstitutionSerializer =
  (): ConnectedPlaidInstitutionSerializerResult => {
    const connectedPlaidAccountSerializer = ConnectedPlaidAccountSerializer();

    const deserialize = (
      responseObject: BackendConnectedPlaidInstitution,
    ): ConnectedPlaidInstitution => {
      const { accounts, id, institution } = responseObject;
      const { institution_id: plaidId, data } = institution;
      const deserializedAccounts: ConnectedPlaidAccount[] = [];
      accounts.forEach((account) => {
        const deserializedAccount =
          connectedPlaidAccountSerializer.deserialize(account);
        deserializedAccounts.push(deserializedAccount);
      });

      const result: ConnectedPlaidInstitution = {
        accounts: deserializedAccounts,
        id,
        name: data.name,
        originalData: data,
        plaidId,
      };
      return result;
    };

    return {
      deserialize,
    };
  };

export default ConnectedPlaidInstitutionSerializer;
