import FormFlowClient from "src/api/clients/formFlowClient";
import PlaidClient from "src/api/clients/plaidClient";
import { getBackendBaseUrl } from "src/common/utils/get-backend-base-url";
import { OnboardingType } from "src/employee/onboarding-v2/components/types";
import FormFlowResource from "./formFlow";
import PlaidResource, { PlaidResourceType } from "./plaid";

const backendBaseUrl = getBackendBaseUrl();

export function getFormFlowClient(
  onboardingType: OnboardingType,
  employerId?: string,
  pbm?: string,
) {
  const client = FormFlowClient(
    backendBaseUrl,
    onboardingType,
    employerId,
    pbm,
  );
  return FormFlowResource({ client });
}

export function getPlaidClient(): PlaidResourceType {
  const client = PlaidClient(backendBaseUrl);
  return PlaidResource({ client });
}
