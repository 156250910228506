import { COOKIES_KEYS } from "src/common/config/cookies";
import { MOLAR_TOKEN_COOKIE } from "src/common/config/token-cookie";
import cookies from "src/common/utils/cookies";
import { getBackendBaseUrl } from "src/common/utils/get-backend-base-url";
import { OnboardingType } from "src/employee/onboarding-v2/components/types";
import { getFormFlowClient } from "../resources/factory";
import { ApiResult, fetcher } from "./api";
import { FORM_FLOW_ID } from "src/common/config/form-flow";
import { ROUTES } from "src/common/config/routes";
import { FormFlowClientType } from "./formFlowClient";
import { getAuthorizationHeader } from "src/common/utils/helpers";

const { getCookie, setCookie, deleteCookie } = cookies();
const backendBaseUrl = getBackendBaseUrl();
let flowClient: FormFlowClientType;

export async function sendEmailConfirmationCode(email: string) {
  return await fetcher<ApiResult<{ detail: string }>>(
    backendBaseUrl + "/auth/email/",
    "POST",
    JSON.stringify({ email }),
  );
}

export async function sendMobileConfirmationCode(mobile: string) {
  return await fetcher<ApiResult<{ detail: string }>>(
    backendBaseUrl + "/auth/mobile/",
    "POST",
    JSON.stringify({ mobile }),
  );
}

export async function validateConfirmationCode(
  payload: { email?: string; mobile?: string; token?: string },
  formFlowId?: string,
) {
  if (!flowClient) {
    flowClient = getFormFlowClient(OnboardingType.MEMBER);
  }
  const result = await fetcher<ApiResult<{ token: string }>>(
    backendBaseUrl + "/auth/token/",
    "POST",
    JSON.stringify(payload),
  );

  if (result?.error) {
    return result;
  }

  if (!result.data?.token) {
    return {
      success: false,
      error: "Invalid server response",
    };
  }

  const token = result.data.token;
  setCookie(MOLAR_TOKEN_COOKIE, token);

  deleteCookie(COOKIES_KEYS.CHAT_TOKEN);
  deleteCookie(COOKIES_KEYS.CHAT_REFRESH_STATE);

  let formFlowResult = undefined;

  if (formFlowId) {
    formFlowResult = await flowClient.postLogin(
      formFlowId,
      { token: payload.token },
      token,
    );
  } else {
    formFlowResult = await flowClient.postLogin(
      { token: payload.token },
      token,
    );
  }

  if (formFlowResult?.error) {
    return formFlowResult;
  }

  return result;
}

export async function validateEmailConfirmationCode(
  email: string,
  token: string,
  id: string | undefined,
) {
  return await validateConfirmationCode({ email, token }, id);
}

export async function validateMobileConfirmationCode(
  mobile: string,
  token: string,
  id: string | undefined,
) {
  return await validateConfirmationCode({ mobile, token }, id);
}

export function isValidToken() {
  const authToken = getCookie(MOLAR_TOKEN_COOKIE);
  return !!authToken;
}

export async function logout(redirectToLogin: boolean = false) {
  const authToken = getCookie(MOLAR_TOKEN_COOKIE);
  const isImpersonating = getCookie(COOKIES_KEYS.IMPERSONATING);

  // Let's not log out from the backend if this is an impersonation session
  // because that would log the member out too
  if (!isImpersonating) {
    await fetcher<ApiResult<{}>>(
      backendBaseUrl + "/user/logout",
      "POST",
      JSON.stringify({ token: authToken }),
      getAuthorizationHeader(),
    );
  }

  deleteCookie(FORM_FLOW_ID);
  deleteCookie(MOLAR_TOKEN_COOKIE);
  let key: keyof typeof COOKIES_KEYS;
  for (key in COOKIES_KEYS) {
    deleteCookie(COOKIES_KEYS[key]);
  }
  // Technically, the redirect upon logout logic is in the portal's layout
  // However we need this to change the `pathname` state, which will trigger the redirect hook
  if (redirectToLogin) {
    if (window.location.href.indexOf("login") === -1) {
      window.location.href = `${ROUTES.EMPLOYEE.LOGIN}?next=${window.location.pathname}`;
    }
  }
}

export default function LoginClient() {
  return {
    sendMobileConfirmationCode,
    sendEmailConfirmationCode,
    validateMobileConfirmationCode,
    validateEmailConfirmationCode,
    isValidToken,
    logout,
  };
}
