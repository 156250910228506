import { ConnectedPlaidAccount as BackendConnectedPlaidAccount } from "src/employee/onboarding/types/backend";
import { ConnectedPlaidAccount } from "src/employee/onboarding/types/form-flow";

export interface ConnectedPlaidAccountSerializerResult {
  // serialize unnecessary since data is read-only
  deserialize: (
    responseObject: BackendConnectedPlaidAccount,
  ) => ConnectedPlaidAccount;
}

const ConnectedPlaidAccountSerializer =
  (): ConnectedPlaidAccountSerializerResult => {
    const deserialize = (
      responseObject: BackendConnectedPlaidAccount,
    ): ConnectedPlaidAccount => {
      const { account_id: id, data } = responseObject;
      const { mask, name, subtype } = data;

      const response: ConnectedPlaidAccount = {
        id,
        mask,
        maxAmount: 0, // TODO: Replace mock data
        name,
        subtype,
      };
      return response;
    };

    return {
      deserialize,
    };
  };

export default ConnectedPlaidAccountSerializer;
