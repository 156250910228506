export const CLIENT_ENDPOINTS = {
  ACTIVATE_MEMBER_ACCOUNT: "/hris/activate_member",
  AMAZON_CONNECT_CHAT_HEALTCHECK: "/messaging/amazon_connect/chat_healthcheck",
  AMAZON_CONNECT_START_CHAT: "/messaging/amazon_connect/start_chat",
  COUNTER_WAITLIST: "/hris/waitlist/{listName}",
  EMPLOYER_LOGO: "/employer/logo/{employerId}",
  PBM_LOGO: "/pbm/logo/{pbmId}",
  DDID_BLURB: "/ddid-blurb/{ddid}",
  FORM_FLOW_EMPLOYEE: "/form_flow/member",
  FORM_FLOW: "/form_flow",
  MAIL_ORDER_ENROLLMENT: "/mail_order_pharmacies",
  MAIL_ORDER_PHARMACY_ENROLLMENTS: "/mail_order_pharmacies/enrollments",
  MEMBER_CONFIRM_MEDS: "/members/confirm_medications",
  MEMBER_EVENTS: "/members/events",
  MEMBER_QUESTION_CONTACT: "/hris/question-contact",
  MEMBER_SHARE_BENEFIT_CONTACT: "/hris/share-benefit-contact",
  MESSAGES: "/messaging/messages",
  MESSAGES_NOTIFICATIONS: "/messaging/notifications",
  PLAID_LINK_TOKEN: "/plaid/linktoken",
  PORTAL_CONFIGURATION_ADMIN: "/admin/",
  PORTAL_CONFIGURATION: "/portal_configuration",
  PRESCRIPTION_RECOMMENDATIONS: "/prescribers/prescription_recommendations",
  PRESCRIPTIONS: "/prescribers/prescriptions",
  SAVINGS_SUMMARY: "/prescribers/savings_summary",
  UPLOAD: "/upload", // mock
  USER: "/user",
  MEMBER: "/member",
  WALLET_BALANCE: "/wallet/balance",
  WALLET_CHECK_WITHDRAWAL: "/wallet/check-withdrawal",
  WALLET_SAVINGS: "/wallet/savings",
  ALERTS: "/messaging/alerts",
  REQUEST_SUPPORT:
    "/member_support/support_request/{prescriptionRecommendationId}",
};
