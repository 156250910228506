import cookies from "src/common/utils/cookies";
import { MOLAR_TOKEN_COOKIE } from "src/common/config/token-cookie";

export function isAppMockMode(appName: "PLAID" | "FIDEL" | "MERGE") {
  const appMockMode = process.env[`APP_${appName}_MOCK_MODE`] || "";
  const appSandboxMode = process.env[`APP_${appName}_SANDBOX_MODE`] || "";
  const appProductionMode = process.env[`APP_${appName}_PRODUCTION_MODE`] || "";
  const globalMockMode = process.env.GLOBAL_MOCK_MODE || "";

  return (
    appMockMode.toLowerCase() === "true" ||
    (appSandboxMode.toLowerCase() != "true" &&
      appProductionMode.toLowerCase() != "true" &&
      globalMockMode.toLowerCase() === "true")
  );
}

export function getAuthorizationHeader() {
  const { getCookie } = cookies();
  const authToken = getCookie(MOLAR_TOKEN_COOKIE);

  return authToken
    ? { Authorization: `Token ${authToken}` }
    : ({} as HeadersInit);
}
