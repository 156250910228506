import { PlaidInstitution as BackendPlaidInstitution } from "src/employee/onboarding/types/backend";
import { PlaidInstitution } from "src/employee/onboarding/types/form-flow";

export interface PlaidInstitutionSerializerResult {
  serialize: (requestObject: PlaidInstitution) => BackendPlaidInstitution;
  deserialize: (responseObject: BackendPlaidInstitution) => PlaidInstitution;
}

const PlaidInstitutionSerializer = (): PlaidInstitutionSerializerResult => {
  const serialize = (
    requestObject: PlaidInstitution,
  ): BackendPlaidInstitution => {
    const { publicToken, ...rest } = requestObject;
    const result: BackendPlaidInstitution = {
      ...rest,
      public_token: publicToken,
    };
    return result;
  };

  const deserialize = (
    responseObject: BackendPlaidInstitution,
  ): PlaidInstitution => {
    const { public_token: publicToken, ...rest } = responseObject;

    const result: PlaidInstitution = {
      ...rest,
      publicToken: publicToken || null,
    };
    return result;
  };

  return {
    serialize,
    deserialize,
  };
};

export default PlaidInstitutionSerializer;
