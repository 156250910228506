import { FormFlow } from "src/employee/onboarding/types/form-flow";
import { InputFieldKey } from "../types/form-flow";

export const EMPTY_EMPLOYEE_FORM_FLOW: FormFlow = {
  [InputFieldKey.ADDRESS_CITY]: null,
  [InputFieldKey.ADDRESS_COUNTRY]: null,
  [InputFieldKey.ADDRESS_POSTAL_CODE]: null,
  [InputFieldKey.ADDRESS_STATE]: null,
  [InputFieldKey.ADDRESS_STREET_1]: null,
  [InputFieldKey.ADDRESS_STREET_2]: null,
  [InputFieldKey.DOB]: null,
  [InputFieldKey.EMAIL]: null,
  [InputFieldKey.FIRST_NAME]: null,
  [InputFieldKey.LAST_NAME]: null,
  [InputFieldKey.MEMBER_ID]: null,
  [InputFieldKey.PHARMACY_PBM]: null,
  [InputFieldKey.PHONE_COUNTRY_CODE]: null,
  [InputFieldKey.PHONE_NUMBER]: null,
  [InputFieldKey.PRIMARY_INSURANCE]: null,
  connectedPlaidInstitutions: [],
  drugAllergies: [],
  employerName: null,
  employerHasLogo: false,
  employerPbmHasLogo: false,
  employerSignupExtraText: null,
  hasAcknowledgedESignAgreement: false,
  hasAcknowledgedPatientAuthorization: false,
  hasAcknowledgedPrivacyPolicy: false,
  hasAcknowledgedTermsOfAgreement: false,
  hasAcknowledgedTermsOfUse: false,
  hasSigned: false,
  hospitalizations: [],
  id: 1,
  lastPage: null,
  medicalConditions: [],
  operations: [],
  optIn: false,
  plaidInstitutions: [],
  supplements: [],
  userMedications: [],
};
