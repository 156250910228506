export const COOKIES_KEYS = {
  CHAT_REFRESH_STATE: "CHAT_REFRESH_STATE",
  CHAT_TOKEN: "CHAT_TOKEN",
  CHAT_USER: "CHAT_USER",
  HAS_ACKNOWLEDGED_INTERVENTIONS: "HAS_ACKNOWLEDGED_INTERVENTIONS",
  IMPERSONATING: "IMPERSONATING",
  IMPERSONATING_ACTIVATED: "IMPERSONATING_ACTIVATED",
  UTM_SOURCE: "UTM_SOURCE",
  REFERRAL_CODE: "REFERRAL_CODE",
};
