import { ReactNode } from "react";
import { ROUTES } from "src/common/config/routes";

export enum FlowKey {
  ACTIVATE_YOUR_ACCOUNT = "activate",
  CONFIRM_MOBILE_PHONE = "confirm-mobile-phone",
  CONFIRM_YOUR_DETAILS = "confirm-your-details",
  DONE = "done",
  EMAIL = "email",
  MOBILE_PHONE = "mobile-phone",
  PRESCRIPTION = "prescription",
  REVIEW_MEDICATIONS = "review-medications",
  REVIEW_PRESCRIPTION_UPDATES = "review-changes",
  SAVINGS_PREVIEW = "savings-preview",
  UPDATED_REPORT = "savings-opportunities",
  LOADING_INSURANCE = "loading-insurance",
  LOADING_PRESCRIPTIONS = "loading-prescriptions",
}

export const EMPLOYEE_FLOW_SEQUENCE = [
  FlowKey.ACTIVATE_YOUR_ACCOUNT,
  FlowKey.LOADING_INSURANCE,
  FlowKey.SAVINGS_PREVIEW,
  FlowKey.MOBILE_PHONE,
  FlowKey.CONFIRM_MOBILE_PHONE,
  FlowKey.EMAIL,
  FlowKey.CONFIRM_YOUR_DETAILS,
  FlowKey.REVIEW_MEDICATIONS,
  FlowKey.LOADING_PRESCRIPTIONS,
  FlowKey.UPDATED_REPORT,
  FlowKey.REVIEW_PRESCRIPTION_UPDATES,
  FlowKey.DONE,
];

export enum LoginType {
  EMAIL = "email",
  PHONE = "phone",
}

export interface Choice {
  key: string;
  label: string;
}

export interface Highlight {
  content?: string;
  count?: string;
  icon?: ReactNode;
  title: string;
}

export interface FlowPage {
  actions: string[];
  activeStep: number;
  authRequired?: boolean;
  back: FlowKey | string | null;
  choices: Choice[];
  eyebrow: string;
  highlights: Highlight[];
  next: FlowKey | string;
  prompt: string;
  skipNext?: string;
  subprompt: ReactNode;
  useProgressBar?: boolean;
  useSavingsRewardsBar?: boolean;
}

export type FlowPageBase = Pick<FlowPage, "back" | "next" | "skipNext"> & {
  authRequired: boolean;
  useProgressBar: boolean;
  useSavingsRewardsBar: boolean;
};

export type Authorization = Pick<
  FlowPage,
  | "back"
  | "eyebrow"
  | "next"
  | "prompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type Card = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "highlights"
  | "next"
  | "prompt"
  | "subprompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type CardsConnected = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "next"
  | "prompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type Connected = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "next"
  | "prompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type ConnectYourBank = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "eyebrow"
  | "next"
  | "prompt"
  | "subprompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type HowCounterWorks = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "eyebrow"
  | "next"
  | "prompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type HowYourCounterCardWorks = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "eyebrow"
  | "next"
  | "prompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type LinkCards = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "next"
  | "prompt"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type Login = FlowPageBase & {
  options: { type: LoginType };
};

export type Prescription = Pick<
  FlowPage,
  "authRequired" | "back" | "useProgressBar" | "useSavingsRewardsBar"
>;

export type Summary = Pick<
  FlowPage,
  | "activeStep"
  | "authRequired"
  | "back"
  | "next"
  | "useProgressBar"
  | "useSavingsRewardsBar"
>;

export type Flow = {
  [FlowKey.ACTIVATE_YOUR_ACCOUNT]: FlowPageBase;
  [FlowKey.LOADING_INSURANCE]: FlowPageBase;
  [FlowKey.LOADING_PRESCRIPTIONS]: FlowPageBase;
  [FlowKey.CONFIRM_MOBILE_PHONE]: FlowPageBase;
  [FlowKey.CONFIRM_YOUR_DETAILS]: FlowPageBase;
  [FlowKey.DONE]: FlowPageBase;
  [FlowKey.EMAIL]: FlowPageBase;
  [FlowKey.MOBILE_PHONE]: FlowPageBase;
  [FlowKey.PRESCRIPTION]: Prescription;
  [FlowKey.REVIEW_MEDICATIONS]: FlowPageBase;
  [FlowKey.REVIEW_PRESCRIPTION_UPDATES]: FlowPageBase;
  [FlowKey.SAVINGS_PREVIEW]: FlowPageBase;
  [FlowKey.UPDATED_REPORT]: FlowPageBase;
};

export const EMPLOYEE_FLOW: Flow = {
  [FlowKey.ACTIVATE_YOUR_ACCOUNT]: {
    authRequired: false,
    back: null,
    next: `/signup/${FlowKey.LOADING_INSURANCE}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },

  [FlowKey.LOADING_INSURANCE]: {
    authRequired: false,
    back: null,
    next: `/signup/${FlowKey.SAVINGS_PREVIEW}`,
    useProgressBar: true,
    useSavingsRewardsBar: false,
  },
  [FlowKey.SAVINGS_PREVIEW]: {
    authRequired: true,
    back: `/signup/${FlowKey.ACTIVATE_YOUR_ACCOUNT}`,
    next: `/signup/${FlowKey.MOBILE_PHONE}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },
  [FlowKey.MOBILE_PHONE]: {
    authRequired: true,
    back: `/signup/${FlowKey.SAVINGS_PREVIEW}`,
    next: `/signup/${FlowKey.CONFIRM_MOBILE_PHONE}`,
    skipNext: `/signup/${FlowKey.EMAIL}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },
  [FlowKey.CONFIRM_MOBILE_PHONE]: {
    authRequired: true,
    back: `/signup/${FlowKey.MOBILE_PHONE}`,
    next: `/signup/${FlowKey.EMAIL}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },
  [FlowKey.EMAIL]: {
    authRequired: true,
    back: `/signup/${FlowKey.CONFIRM_MOBILE_PHONE}`,
    next: `/signup/${FlowKey.CONFIRM_YOUR_DETAILS}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },
  [FlowKey.CONFIRM_YOUR_DETAILS]: {
    authRequired: true,
    // `FlowKey.SAVINGS_PREVIEW` might skip directly to this page
    // So let's set the back page to `FlowKey.SAVINGS_PREVIEW`'s back page
    back: `/signup/${FlowKey.EMAIL}`,
    next: `/signup/${FlowKey.REVIEW_MEDICATIONS}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },
  [FlowKey.REVIEW_MEDICATIONS]: {
    authRequired: true,
    back: `/signup/${FlowKey.CONFIRM_YOUR_DETAILS}`,
    next: `/signup/${FlowKey.LOADING_PRESCRIPTIONS}`,
    // Skip if there's no Prescriptions
    skipNext: `/signup/${FlowKey.DONE}`,
    useProgressBar: true,
    useSavingsRewardsBar: true,
  },

  [FlowKey.LOADING_PRESCRIPTIONS]: {
    authRequired: false,
    back: null,
    next: `/signup/${FlowKey.UPDATED_REPORT}`,
    useProgressBar: true,
    useSavingsRewardsBar: false,
  },
  [FlowKey.UPDATED_REPORT]: {
    authRequired: true,
    back: `/signup/${FlowKey.REVIEW_MEDICATIONS}`,
    next: `/signup/${FlowKey.REVIEW_PRESCRIPTION_UPDATES}`,
    skipNext: `/signup/${FlowKey.DONE}`,
    useProgressBar: true,
    useSavingsRewardsBar: false,
  },
  [FlowKey.REVIEW_PRESCRIPTION_UPDATES]: {
    authRequired: true,
    back: `/signup/${FlowKey.UPDATED_REPORT}`,
    next: `/signup/${FlowKey.DONE}`,
    useProgressBar: true,
    useSavingsRewardsBar: false,
  },
  [FlowKey.DONE]: {
    authRequired: false,
    back: `/signup/${FlowKey.REVIEW_PRESCRIPTION_UPDATES}`,
    next: ROUTES.EMPLOYEE.PORTAL.ROOT,
    useProgressBar: true,
    useSavingsRewardsBar: false,
  },
  [FlowKey.PRESCRIPTION]: {
    authRequired: true,
    back: null,
  },
};
