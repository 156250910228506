import {
  FormFlow as BackendEmployeeFormFlow,
  PlaidInstitution as BackendPlaidInstitution,
} from "src/employee/onboarding/types/backend";
import {
  ConnectedPlaidInstitution,
  FormFlow as EmployeeFormFlow,
  InputFieldKey,
  PlaidInstitution,
} from "src/employee/onboarding/types/form-flow";
import ConnectedPlaidInstitutionSerializer from "./connectedPlaidInstitutionSerializer";
import PlaidInstitutionSerializer from "./plaidInstitutionSerializer";
import { EMPTY_EMPLOYEE_FORM_FLOW } from "src/employee/onboarding/config/employee-form-flow";
import { FlowKey } from "src/employee/onboarding/components/FlowContent";

export interface EmployeeFormFlowSerializerResult {
  serialize: (requestObject: EmployeeFormFlow) => BackendEmployeeFormFlow;
  deserialize: (responseObject: BackendEmployeeFormFlow) => EmployeeFormFlow;
}

const EmployeeFormFlowSerializer = () => {
  const plaidInstitutionSerializer = PlaidInstitutionSerializer();
  const connectedPlaidInstitutionSerializer =
    ConnectedPlaidInstitutionSerializer();

  function serialize(requestObject: EmployeeFormFlow) {
    const {
      [InputFieldKey.ADDRESS_CITY]: addressCity,
      [InputFieldKey.ADDRESS_COUNTRY]: addressCountry,
      [InputFieldKey.ADDRESS_POSTAL_CODE]: addressPostalCode,
      [InputFieldKey.ADDRESS_STATE]: addressState,
      [InputFieldKey.ADDRESS_STREET_1]: addressStreet1,
      [InputFieldKey.ADDRESS_STREET_2]: addressStreet2,
      [InputFieldKey.DOB]: dob,
      [InputFieldKey.EMAIL]: email,
      [InputFieldKey.FIRST_NAME]: firstName,
      [InputFieldKey.LAST_NAME]: lastName,
      [InputFieldKey.MEMBER_ID]: memberId,
      [InputFieldKey.PHARMACY_PBM]: pharmacyPbm,
      [InputFieldKey.PHONE_COUNTRY_CODE]: phoneCountryCode,
      [InputFieldKey.PHONE_NUMBER]: phoneNumber,
      [InputFieldKey.PRIMARY_INSURANCE]: primaryInsurance,
      connectedPlaidInstitutions,
      employerName,
      hasAcknowledgedPatientAuthorization,
      hasAcknowledgedTermsOfUse,
      optIn,
      plaidInstitutions,
      ...remaining
    } = requestObject;

    const serializedPlaidInstitutions: BackendPlaidInstitution[] = [];

    // Add connected institutions to `plaid_institutions` property
    // Connected institutions not added will be deactivated (removed)
    connectedPlaidInstitutions.forEach((connectedPlaidInstitution) => {
      const serializedPlaidInstitution: PlaidInstitution = {
        publicToken: null,
        ...connectedPlaidInstitution.originalData,
      };
      serializedPlaidInstitutions.push(serializedPlaidInstitution);
    });

    plaidInstitutions.forEach((plaidInstitution) => {
      const serializedPlaidInstitution =
        plaidInstitutionSerializer.serialize(plaidInstitution);
      serializedPlaidInstitutions.push(serializedPlaidInstitution);
    });

    const result = { ...remaining } as BackendEmployeeFormFlow;
    result.address_city = addressCity;
    result.address_country = addressCountry;
    result.address_postal_code = addressPostalCode;
    result.address_state = addressState;
    result.address_street1 = addressStreet1;
    result.address_street2 = addressStreet2;
    result.dob = dob;
    result.email = email;
    result.employer_name = employerName;
    result.first_name = firstName;
    result.has_acknowledged_patient_authorization =
      hasAcknowledgedPatientAuthorization;
    result.has_acknowledged_terms_of_use = hasAcknowledgedTermsOfUse;
    result.last_name = lastName;
    result.member_id = memberId;
    result.pharmacy_pbm = pharmacyPbm;
    result.phone_country_code = phoneCountryCode;
    result.phone_number = phoneNumber;
    result.primary_insurance = primaryInsurance;
    result["opt-in"] = optIn;
    result.plaid_institutions = serializedPlaidInstitutions;
    return result;
  }

  function deserialize(responseObject: BackendEmployeeFormFlow) {
    if (!responseObject) return EMPTY_EMPLOYEE_FORM_FLOW;
    const {
      ["opt-in"]: optIn,
      address_city: addressCity,
      address_country: addressCountry,
      address_postal_code: addressPostalCode,
      address_state: addressState,
      address_street1: addressStreet1,
      address_street2: addressStreet2,
      connected_plaid_institutions,
      dob,
      email,
      employer_name: employerName,
      employer_has_logo: employerHasLogo,
      employer_signup_extra_text: employerSignupExtraText,
      employer_pbm: employerPbm,
      employer_pbm_has_logo: employerPbmHasLogo,
      first_name: firstName,
      has_acknowledged_patient_authorization:
        hasAcknowledgedPatientAuthorization,
      has_acknowledged_terms_of_use: hasAcknowledgedTermsOfUse,
      id,
      last_page: lastPage,
      last_name: lastName,
      member_id: memberId,
      mobile,
      pharmacy_pbm: pharmacyPbm,
      phone_country_code: phoneCountryCode,
      plaid_institutions,
      primary_insurance: primaryInsurance,
    } = responseObject;

    const plaidInstitutions = (plaid_institutions ?? []).reduce((acc, item) => {
      const deserialized = plaidInstitutionSerializer.deserialize(item);
      return [...acc, deserialized];
    }, [] as PlaidInstitution[]);

    const connectedPlaidInstitutions = (
      connected_plaid_institutions ?? []
    ).reduce((acc, item) => {
      const deserialized =
        connectedPlaidInstitutionSerializer.deserialize(item);
      return [...acc, deserialized];
    }, [] as ConnectedPlaidInstitution[]);

    let deserializedLastPage: FlowKey | null = null;

    if (!!lastPage && Object.values(FlowKey).includes(lastPage as FlowKey)) {
      deserializedLastPage = lastPage as FlowKey;
    }

    const response: EmployeeFormFlow = {
      [InputFieldKey.ADDRESS_CITY]: addressCity || null,
      [InputFieldKey.ADDRESS_COUNTRY]: addressCountry || null,
      [InputFieldKey.ADDRESS_POSTAL_CODE]: addressPostalCode || null,
      [InputFieldKey.ADDRESS_STATE]: addressState || null,
      [InputFieldKey.ADDRESS_STREET_1]: addressStreet1 || null,
      [InputFieldKey.ADDRESS_STREET_2]: addressStreet2 || null,
      [InputFieldKey.DOB]: dob || null,
      [InputFieldKey.EMAIL]: email || null,
      [InputFieldKey.FIRST_NAME]: firstName || null,
      [InputFieldKey.LAST_NAME]: lastName || null,
      [InputFieldKey.MEMBER_ID]: memberId || null,
      [InputFieldKey.PHARMACY_PBM]: pharmacyPbm || null,
      [InputFieldKey.PHONE_COUNTRY_CODE]: phoneCountryCode || null,
      [InputFieldKey.PHONE_NUMBER]: mobile || null,
      [InputFieldKey.PRIMARY_INSURANCE]: primaryInsurance || null,
      connectedPlaidInstitutions,
      drugAllergies: [],
      employerName: employerName ?? null,
      employerHasLogo: !!employerHasLogo,
      employerPbm: employerPbm ?? null,
      employerPbmHasLogo: !!employerPbmHasLogo,
      employerSignupExtraText: employerSignupExtraText ?? null,
      hasAcknowledgedESignAgreement: false, // hook up to backend
      hasAcknowledgedPatientAuthorization:
        !!hasAcknowledgedPatientAuthorization,
      hasAcknowledgedPrivacyPolicy: false, // hook up to backend
      hasAcknowledgedTermsOfAgreement: false, // hook up to backend
      hasAcknowledgedTermsOfUse: !!hasAcknowledgedTermsOfUse,
      hasSigned: false, // hook up to backend
      hospitalizations: [],
      id,
      lastPage: deserializedLastPage,
      medicalConditions: [],
      operations: [],
      optIn: !!optIn,
      plaidInstitutions,
      supplements: [],
      userMedications: [],
    };

    return response;
  }

  return {
    serialize,
    deserialize,
  };
};

export default EmployeeFormFlowSerializer;
