import { OnboardingType } from "src/employee/onboarding-v2/components/types";
import { FormFlowClientType } from "src/api/clients/formFlowClient";
import EmployeeFormFlowSerializer, {
  EmployeeFormFlowSerializerResult,
} from "src/api/serializers/employeeFormFlow";
import { FlowKey } from "src/employee/onboarding/components/FlowContent";

interface FormFlowProps {
  client: FormFlowClientType;
}

const FormFlowResource = ({ client }: FormFlowProps): FormFlowClientType => {
  let serializer: EmployeeFormFlowSerializerResult | undefined = undefined;
  const flowType: OnboardingType = client.flowType;

  switch (flowType) {
    case OnboardingType.MEMBER:
      serializer = EmployeeFormFlowSerializer();
      break;
    case OnboardingType.EMPLOYER:
      break;
  }

  const serialize = (payload: any) => {
    if (serializer) {
      return serializer.serialize(payload);
    }

    return payload;
  };

  const deserialize = (payload: any) => {
    if (serializer) {
      return serializer.deserialize(payload);
    }

    return payload;
  };

  const createFormFlow = async (page: FlowKey) => {
    const result = await client.createFormFlow(page);
    result.data = deserialize(result?.data);
    return result;
  };

  const updateFormFlow = async (
    page: FlowKey,
    action: string,
    payload: object,
  ) => {
    payload = serialize(payload);
    const result = await client.updateFormFlow(page, action, payload);
    result.data = deserialize(result?.data);
    return result;
  };

  const postLogin = async (...payload: (object | string)[]) => {
    return await client.postLogin(...payload);
  };

  const getFormFlow = async (page: string) => {
    const result = await client.getFormFlow(page);
    result.data = deserialize(result.data);
    return result;
  };

  return {
    flowType,
    createFormFlow,
    updateFormFlow,
    postLogin,
    getFormFlow,
  };
};

export default FormFlowResource;
