import { FlowKey } from "src/employee/onboarding/components/FlowContent";
import { MedicationType } from "src/employee/activity/medical-history/types";

export interface ConnectedPlaidAccount {
  id: string;
  mask: string;
  maxAmount: number; // TODO: mock data to redesign
  name: string;
  subtype: string;
}

export interface ConnectedPlaidInstitution {
  accounts: ConnectedPlaidAccount[];
  id: number;
  name: string;
  originalData: object; // Used to return to backend the same data it had returned
  plaidId: string;
}

export type DrugAllergy = string;

export interface EmployeeInformation {
  [InputFieldKey.ADDRESS_CITY]: string | null;
  [InputFieldKey.ADDRESS_COUNTRY]: string | null;
  [InputFieldKey.ADDRESS_POSTAL_CODE]: string | null;
  [InputFieldKey.ADDRESS_STATE]: string | null;
  [InputFieldKey.ADDRESS_STREET_1]: string | null;
  [InputFieldKey.ADDRESS_STREET_2]: string | null;
  [InputFieldKey.DOB]: string | null;
  [InputFieldKey.EMAIL]: string | null;
  [InputFieldKey.FIRST_NAME]: string | null;
  [InputFieldKey.LAST_NAME]: string | null;
  [InputFieldKey.MEMBER_ID]: string | null;
  [InputFieldKey.PHARMACY_PBM]: string | null;
  [InputFieldKey.PHONE_COUNTRY_CODE]: string | null;
  [InputFieldKey.PHONE_NUMBER]: string | null;
  [InputFieldKey.PRIMARY_INSURANCE]: string | null;
  employerName: string | null;
  employerHasLogo: boolean;
  employerPbm?: string | null;
  employerPbmHasLogo: boolean;
}

export interface EmployerInformation {
  employerName: string | null;
  employerSignupExtraText: string | null;
}

export type FormFlow = EmployeeInformation &
  EmployerInformation &
  MedicalHistory &
  OnboardingHistory &
  OptInNotifications &
  PlaidInstitutions & {
    id: number;
  };

export interface Hospitalization {
  date: string | null;
  reason: string;
}

export enum InputFieldKey {
  ADDRESS_CITY = "addressCity",
  ADDRESS_COUNTRY = "addressCountry",
  ADDRESS_POSTAL_CODE = "addressPostalCode",
  ADDRESS_STATE = "addressState",
  ADDRESS_STREET_1 = "addressStreet1",
  ADDRESS_STREET_2 = "addressStreet2",
  AMOUNT = "amount",
  DOB = "dob",
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  MEMBER_ID = "memberId",
  PHARMACY_PBM = "pharmacyPbm",
  PHONE_COUNTRY_CODE = "phoneCountryCode",
  PHONE_NUMBER = "phoneNumber",
  PRIMARY_INSURANCE = "primaryInsurance",
}

export type MedicalCondition = string;

export interface MedicalHistory {
  drugAllergies: DrugAllergy[];
  hospitalizations: Hospitalization[];
  medicalConditions: MedicalCondition[];
  operations: Operation[];
  supplements: Supplement[];
  userMedications: UserMedication[];
}

export interface MemberActivationPayload {
  dob: string;
  memberId: string;
}

export interface MemberActivationPersonResponse {
  dob: string;
  memberId: string;
  name: string;
}

export interface MemberActivationResponse {
  token: string | null;
  persons: MemberActivationPersonResponse[];
}

export interface OnboardingHistory {
  lastPage: FlowKey | null;
}

export interface Operation {
  date: string | null;
  name: string;
  reason: string | null;
}

export interface OptInNotifications {
  hasAcknowledgedESignAgreement: boolean;
  hasAcknowledgedPatientAuthorization: boolean;
  hasAcknowledgedPrivacyPolicy: boolean;
  hasAcknowledgedTermsOfAgreement: boolean;
  hasAcknowledgedTermsOfUse: boolean;
  hasSigned: boolean;
  optIn: boolean;
}

export interface PlaidInstitution {
  [key: string]: any; // Allow any extra data not explicitly used to be informed
  publicToken: string | null;
}

export enum PlaidTypeInstitution {
  BANK_OF_AMERICA = "bank of america",
  HEALTH_EQUITY = "health-equity",
}

export interface PlaidInstitutions {
  plaidInstitutions: PlaidInstitution[];
  connectedPlaidInstitutions: ConnectedPlaidInstitution[];
}

export interface Supplement {
  dateStarted: string | null;
  dosage: string | null;
  frequency: string | null;
  name: string;
  reason: string | null;
}

export interface UserMedication {
  dateStarted: string | null;
  dosage: string | null;
  drugName: string;
  frequency: string | null;
  reason: string | null;
  type: MedicationType;
}
