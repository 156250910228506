import { ApiResult, fetcher } from "./api";
import { getAuthorizationHeader } from "src/common/utils/helpers";
import { CLIENT_ENDPOINTS } from "src/common/config/client-endpoints";

export interface PlaidResult<TData = any> {
  message: {
    Success: boolean;
    Data: TData;
  };
}

export interface PlaidClientBase<TLinkTokenResult = any> {
  getLinkToken: () => Promise<TLinkTokenResult>;
}

export interface PlaidClientType extends PlaidClientBase<PlaidResult<string>> {}

const PlaidClient = (backendBaseUrl: string): PlaidClientType => {
  const getLinkToken = async (): Promise<PlaidResult<string>> => {
    const result = await fetcher<PlaidResult>(
      `${backendBaseUrl}${CLIENT_ENDPOINTS.PLAID_LINK_TOKEN}`,
      "GET",
      null,
      getAuthorizationHeader(),
    );

    if ((result as ApiResult).error) {
      return {
        message: {
          Success: false,
          Data: (result as ApiResult).error as string,
        },
      };
    }

    return result as PlaidResult;
  };

  return {
    getLinkToken,
  };
};

export default PlaidClient;
